<template>
    <div class="add-new-sr-type">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.srTypeCreate')"
            :title="$t('messages.srTypeCreate')"
            @submitPressed="submitPressed"
        />
        <resource-form :resource-object="srType" :resource="$ServiceRequestTypes" base-url="/settings/sr-types"
                       create-message="messages.srTypeCreated" update-message="messages.srTypeUpdated" :action="action"
                       :emails="false" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../../Settings/components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            action: null,
            srType: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
